
  import Vue from 'vue'

  export default Vue.extend({
    name: 'Home-View',
    data: () => ({
      approaches: [
        { title: 'Community-Based Participatory Research', to: '/CBPR' },
        { title: 'Community Engaged Research', to: '/CENR'},
        { title: 'Deliberative Processes', to: '/deliberative-processes' },
        { title: 'Deverkas Conceptual Model for Stakeholder Engagement in Comparative Effectiveness Research', to: '/deverka-conceptual' },
        { title: 'Experienced-Based Co-Design (EBCD)', to: '/EBCD' },
        { title: 'PCORI Dissemination and Implementation Framework', to: '/PCORI' }
      ],
      methods: [
        { title: 'Appreciative Inquiry', to: '/appreciative-inquiry' },
        { title: 'Boot Camp Translation', to: '/boot-camp' },
        { title: 'Citizen Juries', to: '/citizen-juries' },
        { title: 'Community Engagement Studios', to: '/community-engagement' },
        { title: 'Concept Mapping', to: '/concept-mapping' },
        { title: 'Deliberative Polling', to: '/deliberative-polling' },
        { title: 'Delphi Technique', to: '/delphi-technique' },
        { title: 'Human-Centered Design', to: '/human-centered-design' },
        { title: 'I-Corps', to: '/i-corps' },
        { title: 'Online Collaborative Platforms', to: '/online-collaborative-platforms' },
        { title: 'Online Communities', to: '/online-communities' },
        { title: 'Stakeholder Panel / Advisory Group', to: '/stakeholder-panel' },
      ],
      tools: [
        { title: '25/10 Crowd-sourcing', to: '/crowdsourcing' },
        { title: 'Conversation Cafe', to: '/conversation-cafe' },
        { title: 'Discovery and Action Dialogues', to: 'discovery-action-dialogues' },
        { title: 'Ecocycle Planning', to: '/ecocycle-planning' },
        { title: 'Focus Groups', to: '/focus-groups' },
        { title: 'Key Informant Interviews', to: '/key-informant' },
        { title: 'Nominal Group Technique', to: '/nominal-group-technique' },
        { title: 'Purpose To Practice', to: '/purpose-to-practice' },
        { title: 'Simple Ethnography', to: '/simple-ethnography' },
        { title: 'Social Network Webbing', to: '/social-network-webbing' },
        { title: 'Survey Questionnaire', to: '/survey-questionnaire' },
        { title: 'Town Hall Meetings', to: '/town-hall' },
        { title: 'User Experience Fishbowl', to: '/user-experience-fishbowl' }
      ]
    }),
    components: {
    },
  })
