<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Navigation
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>DICEMethods.org</v-app-bar-title>
    </v-app-bar>
    <v-main>
      <v-container >
        <v-row no-gutters>
            <v-col sm="1" cols="2">
                <a href="/"><img class="d2v-sen-banner-logo pr-md-3 pr-2" src="./assets/SEN_logo.png" alt="Stakeholder Engagement Navigator Logo" /></a>
            </v-col>
            <v-col md="6" cols="9" class="pt-lg-8">
                <p class="d2v-header-title d2v-header-title-heading"><a href="/"><strong>STAKEHOLDER ENGAGEMENT NAVIGATOR</strong></a></p>
                <p class="d2v-header-title d2v-header-title-subheading">
                    DICEmethods.org | <strong>D</strong>issemination, <strong>I</strong>mplementation, <strong>C</strong>ommunication, and <strong>E</strong>ngagement
                    <br />A guide for health researchers
                </p>
            </v-col>
            <v-col md="5" cols="8" class="pt-6">
                <v-row>
                    <v-col class="hidden-sm-and-down mt-1" cols="10">
                        <img class="d2v-banner-logo" src="./assets/D2V_logo.png" alt="D2V Data To Science Patient Value Logo" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
      <router-view></router-view>
    </v-main>
    <v-footer class="px-lg-15 py-lg-5" padless color="grey darken-2">
      <v-row justify="end">
        <v-col class="text-center white--text text-sm-body-2" md="6" cols="12">
            <p>The Stakeholder Engagement Navigator is a service of the <a href="https://www.ucdenver.edu/academics/colleges/medicalschool/programs/d2V/Pages/D2V.aspx" target="_blank" style="color:white !important">Data Science to Patient Value Initiative</a> at the<br />University of Colorado Anschutz Medical Campus</p>
        </v-col>
        <v-col md="3" cols="12" class="align-content-md-end text-center white--text">
            <p><a href="https://www.ucdenver.edu/academics/colleges/medicalschool/programs/d2V/about/Pages/about.aspx" style="color:white !important" target="_blank">About us</a> | Find us on <a href="https://www.facebook.com/CUMedicine" target="_blank" style="text-decoration: none"><v-icon class="px-3" style="color:white;">mdi-facebook</v-icon></a> <a href="https://twitter.com/CUMedicalSchool" target="_blank" style="text-decoration: none"><v-icon style="color:white;">mdi-twitter</v-icon></a></p>
        </v-col>
    </v-row>
    </v-footer>
  </v-app>
</template>
<script>
  import './css/site.css'

  export default {
    name: 'App',
    watch: {
      $route: {
        immediate: true, 
        handler(to) {
          document.title = to.meta.title || 'D2V Default Title'
        }
      }
    },
    data: () => ({
      drawer: null,
      items: [
        { title: 'Home', icon: 'mdi-home', to: '/' },
        { title: 'Education Hub', icon: 'mdi-head-check-outline', to: '/stakeholder-engagement' },
        { title: 'Find Engagement Strategies', icon: 'mdi-cloud-search-outline', to: '/tool' },
        { title: 'Glossary', icon: 'mdi-book-alphabet', to: '/glossary' },
        // { title: 'Contact Us', icon: 'mdi-card-account-mail', to: '/contact-us' },
      ],
    }),
  };
</script>