export default class Breadcrumb {
    text: string;
    disabled: boolean;
    link: boolean;
    href?: string;

    constructor() {
        this.text = '';
        this.disabled = false;
        this.link = false;
    }
}