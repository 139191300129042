
    import { Vue, Component, Prop } from "vue-property-decorator";

    @Component({})
    export default class StartPage extends Vue {
        @Prop() hasBeenStarted!: boolean;
        isLoading = false;
        showToaster = true;
        hideToaster = false;

        mounted() {
            this.isLoading = false;
            
            setTimeout(() => {
                if (this.showToaster) {
                    this.showToaster = !this.showToaster;
                    this.hideToaster = !this.hideToaster;
                }
            }, 10000);
        }

        startNavigator() {
            this.showToaster = false;
            this.$emit('startNavigator');
        }

        setToaster() {
            this.showToaster = !this.showToaster;
            this.hideToaster = !this.hideToaster;
            
        }
    }
