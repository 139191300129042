import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VContainer,{staticClass:"d2v-container"},[_c(VRow,[_c(VCol,{attrs:{"md":"10","cols":"12"}}),_c(VCol,{attrs:{"md":"2","cols":"12"}},[(_vm.isStarted)?_c(VBtn,{staticClass:"white--text",attrs:{"x-large":"","color":"yellow darken-2"},on:{"click":_vm.relaunch}},[_vm._v("RE-LAUNCH"),_c('br'),_vm._v("TOOL")]):_vm._e()],1)],1),(_vm.error.length > 0)?_c(VAlert,{attrs:{"text":"","prominent":"","type":"error","icon":"far fa-exclamation-circle"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c(VBreadcrumbs,{attrs:{"large":"","divider":">","items":_vm.activeBreadcrumbs},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c(VBreadcrumbsItem,{attrs:{"href":item.href,"link":item.link,"disabled":item.disabled},on:{"click":function($event){return _vm.breadcrumbClick(item.text)}}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}])}),(!_vm.isStarted)?_c('start-page',{attrs:{"has-been-started":_vm.hasBeenStarted},on:{"startNavigator":_vm.startNavigator}}):_vm._e(),(_vm.isStarted && _vm.selectedResearchStages.length === 0)?_c('research-stage-page',{on:{"trackResearchStages":_vm.trackResearchStages,"relaunch":_vm.relaunch}}):_vm._e(),(_vm.isStarted && _vm.selectedResearchStages.length > 0 && _vm.selectedEngagementPurposes.length === 0)?_c('engagement-purpose-page',{attrs:{"selected-stages-display":_vm.selectedResearchStagesDisplay,"purposes":_vm.filteredPurposes},on:{"trackEngagementPurposes":_vm.trackEngagementPurposes,"goBackToStages":_vm.goBackToStages}}):_vm._e(),(_vm.isStarted && _vm.selectedEngagementPurposes.length > 0)?_c('bubble-page',{attrs:{"selected-stages-display":_vm.selectedResearchStagesDisplay,"selected-purposes":_vm.selectedEngagementPurposes,"filtered-methods":_vm.filteredMethods}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }