
    import { Vue, Component } from "vue-property-decorator";

    import Method from "../models/Method";
    
    @Component({})
    export default class ResearchStagePage extends Vue {
        selectedStages:(keyof Method)[] = [];

        trackResearchStages() {
            this.$emit("trackResearchStages", this.selectedStages);
        }

        goToStartPage() {
            this.$emit("relaunch");
        }
    }
