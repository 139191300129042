import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Tool from '../Tool/components/tool.vue'
import VueAnalytics from 'vue-analytics'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    meta: { title: 'Home - Stakeholder Engagement'},
    component: HomeView
  },
  {
    path: '/tool',
    name: 'Tool',
    meta: { title: 'Selection Tool - Stakeholder Engagement'},
    component: Tool
  },
  {
    path: '/glossary',
    name: 'DiceMethods Glossary',
    meta: { title: 'Glossary - Stakeholder Engagement'},
    component: () => import('../views/D2VGlossary.vue')
  },
  {
    path: '/stakeholder-engagement',
    name: 'Stakeholder Engagement',
    meta: { title: 'Education Hub - Stakeholder Engagement'},
    component: () => import('../views/Education Hub/StakeholderEngagementView.vue')
  },
  {
    path: '/what-is-stakeholder-engagement',
    name: 'What is Stakeholder Engagement',
    meta: { title: 'What is stakeholder engagement?'},
    component: () => import('../views/Education Hub/The Basics/WhatIsStakeholderEngagementView.vue')
  },
  {
    path: '/why-engage-stakeholders',
    name: 'Why Engage Stakeholders',
    meta: { title: 'Why engage stakeholders'},
    component: () => import('../views/Education Hub/The Basics/WhyEngageStakeholdersView.vue')
  },
  {
    path: '/research-stakeholder-engagement',
    name: 'Need for more research on stakeholder engagement',
    meta: { title: 'Need for more research on stakeholder engagement'},
    component: () => import('../views/Education Hub/The Basics/ResearchStakeholderEngagementView.vue')
  },
  {
    path: '/core-principles',
    name: 'What are the core principles of stakeholder engagement',
    meta: { title: 'What are the core principles of stakeholder engagement?'},
    component: () => import('../views/Education Hub/The Basics/CorePrinciplesView.vue')
  },
  {
    path: '/identify-stakeholders',
    name: 'How to identify stakeholders and establish their roles',
    meta: { title: 'How to identify stakeholders and establish their roles'},
    component: () => import('../views/Education Hub/The Basics/IdentifyStakeholdersView.vue')
  },
  {
    path: '/approach-method-tool',
    name: 'What is the difference between a stakeholder engagement approach, method, and tool',
    meta: { title: 'What is the difference between a stakeholder engagement approach, method, and tool'},
    component: () => import('../views/Education Hub/A Deeper Dive/ApproachMethodToolView.vue')
  },
  {
    path: '/explore-engagement',
    name: 'Explore engagement approaches for your project',
    meta: { title: 'Explore engagement approaches for your project'},
    component: () => import('../views/Education Hub/A Deeper Dive/ExploreEngagementView.vue')
  },
  {
    path: '/background-development',
    name: 'Background and development of the Stakeholder Engagement Selection Tool',
    meta: { title: 'Background and development of the Stakeholder Engagement Selection Tool'},
    component: () => import('../views/Education Hub/About the Website/BackgroundDevelopmentView.vue')
  },
  {
    path: '/website-organizing-framework',
    name: 'Website Organizing Framework',
    meta: { title: 'Website Organizing Framework - Stakeholder Engagement'},
    component: () => import('../views/Education Hub/About the Website/WebsiteOrganizingFrameworkView.vue')
  },
  {
    path: '/CBPR',
    name: 'Community Based Participatory Research',
    meta: { title: 'Community Based Participatory Research - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Approaches/CBPR.vue')
  },
  {
    path: '/CENR',
    name: 'Community Engaged Research',
    meta: { title: 'Community Engaged Research - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Approaches/CENR.vue')
  },
  {
    path: '/deliberative-processes',
    name: 'Deliberative Processes',
    meta: { title: 'Deliberative Processes - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Approaches/DeliberativeProcesses.vue')
  },
  {
    path: '/deverka-conceptual',
    name: 'Deverka Conceptual Model',
    meta: { title: 'Deverkas Conceptual Model for Stakeholder Engagement in Comparative Effectiveness Research - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Approaches/DeverkasConceptualModel.vue')
  },
  {
    path: '/EBCD',
    name: 'Experience-Based Co-Design',
    meta: { title: 'Experience-Based Co-Design - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Approaches/EBCD.vue')
  },
  {
    path: '/PCORI',
    name: 'PCORI Dissemination and Implementation Framework',
    meta: { title: 'PCORI Dissemination and Implementation Framework - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Approaches/PCORI.vue')
  },
  {
    path: '/appreciative-inquiry',
    name: 'Appreciative Inquiry',
    meta: { title: 'Appreciative Inquiry - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/AppreciativeInquiry.vue')
  },
  {
    path: '/boot-camp',
    name: 'Boot Camp Translation',
    meta: { title: 'Boot Camp Translation - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/BootCamp.vue')
  },
  {
    path: '/citizen-juries',
    name: 'Citizen Juries',
    meta: { title: 'Citizen Juries - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/CitizenJuries.vue')
  },
  {
    path: '/community-engagement',
    name: 'Community Engagement Studios',
    meta: { title: 'Community Engagement Studios - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/CommunityEngagement.vue')
  },
  {
    path: '/concept-mapping',
    name: 'Concept Mapping',
    meta: { title: 'Concept Mapping - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/ConceptMapping.vue')
  },
  {
    path: '/deliberative-polling',
    name: 'Deliberative Polling',
    meta: { title: 'Deliberative Polling - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/DeliberativePolling.vue')
  },
  {
    path: '/delphi-technique',
    name: 'Delphi Technique',
    meta: { title: 'Delphi Technique - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/DelphiTechnique.vue')
  },
  {
    path: '/human-centered-design',
    name: 'Human-Centered Design',
    meta: { title: 'Human-Centered Design - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/HumanCenteredDesign.vue')
  },
  {
    path: '/i-corps',
    name: 'I-Corps',
    meta: { title: 'I-Corps - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/ICorps.vue')
  },
  {
    path: '/online-collaborative-platforms',
    name: 'Online Collaborative Platforms',
    meta: { title: 'Online Collaborative Platforms - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/OnlineCollaborativePlatforms.vue')
  },
  {
    path: '/online-communities',
    name: 'Online Communities',
    meta: { title: 'Online Communities - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/OnlineCommunities.vue')
  },
  {
    path: '/stakeholder-panel',
    name: 'Stakeholder Panel / Advisory Group',
    meta: { title: 'Stakeholder Panel/Advisory Group - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Methods/StakeholderPanel.vue')
  },
  {
    path: '/crowdsourcing',
    name: '25/10 Crowd-sourcing',
    meta: { title: '25/10 Crowd-sourcing - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/25-10Crowdsourcing.vue')
  },
  {
    path: '/conversation-cafe',
    name: 'Liberating Structures Conversation Cafe',
    meta: { title: 'Liberating Structures Conversation Cafe - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/ConversationCafe.vue')
  },
  {
    path: '/discovery-action-dialogues',
    name: 'Discovery and Action Dialogues',
    meta: { title: 'Discovery and Action Dialogues - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/DiscoveryActionDialogues.vue')
  },
  {
    path: '/ecocycle-planning',
    name: 'Liberating Structures Ecocycle Planning',
    meta: { title: 'Liberating Structures Ecocycle Planning - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/EcocyclePlanning.vue')
  },
  {
    path: '/focus-groups',
    name: 'Focus Groups',
    meta: { title: 'Focus Groups - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/FocusGroups.vue')
  },
  {
    path: '/key-informant',
    name: 'Key Informant Interviews',
    meta: { title: 'Key Informant Interviews - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/KeyInformant.vue')
  },
  {
    path: '/nominal-group-technique',
    name: 'Nominal Group Technique',
    meta: { title: 'Nominal Group Technique - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/NominalGroupTechnique.vue')
  },
  {
    path: '/purpose-to-practice',
    name: 'Purpose to Practice',
    meta: { title: 'Purpose to Practice - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/PurposeToPractice.vue')
  },
  {
    path: '/simple-ethnography',
    name: 'Simple Ethnography',
    meta: { title: 'Simple Ethnography - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/SimpleEthnography.vue')
  },
  {
    path: '/social-network-webbing',
    name: 'Social Network Webbing',
    meta: { title: 'Social Network Webbing - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/SocialNetworkWebbing.vue')
  },
  {
    path: '/survey-questionnaire',
    name: 'Survey Questionnaire',
    meta: { title: 'Survey Questionnaire - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/SurveyQuestionnaire.vue')
  },
  {
    path: '/town-hall',
    name: 'Town Hall Meetings',
    meta: { title: 'Town Hall Meetings - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/TownHall.vue')
  },
  {
    path: '/user-experience-fishbowl',
    name: 'User Experience Fishbowl',
    meta: { title: 'User Experience Fishbowl - Stakeholder Engagement'},
    component: () => import('../views/Strategy Fact Sheets/Tools/UserExperienceFishbowl.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes 
})

Vue.use(VueAnalytics, {
  id: 'UA-210936071-1',
  router
})
export default router
