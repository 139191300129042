import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"grey lighten-5"},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('h2',[_vm._v("Research Stage: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.selectedStagesDisplay.join(', ')))])]),_c('hr'),_c('p',{staticClass:"d2v-tool-question font-weight-bold"},[_vm._v("Why do you want to engage stakeholders?")]),_vm._l((_vm.purposes),function(purpose){return _c(VCheckbox,{key:purpose.Name,attrs:{"label":purpose.Value,"value":purpose},model:{value:(_vm.selectedPurposes),callback:function ($$v) {_vm.selectedPurposes=$$v},expression:"selectedPurposes"}})})],2),_c(VCol,{attrs:{"cols":"6"}},[_c(VImg,{staticClass:"mt-10",attrs:{"src":require("../../assets/purpose-page.jpg"),"max-height":"250","contain":""}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"md":"4","cols":"12"}}),_c(VCol,{attrs:{"md":"2","cols":"12"}},[_c(VBtn,{staticClass:"white--text",staticStyle:{"width":"100%"},attrs:{"x-large":"","color":"yellow darken-2"},on:{"click":_vm.goBackToStages}},[_c('i',{staticClass:"far fa-long-arrow-left"}),_vm._v(" Back")])],1),_c(VCol,{attrs:{"md":"2","cols":"12"}},[(_vm.selectedPurposes.length > 0)?_c(VBtn,{staticClass:"white--text",staticStyle:{"width":"100%"},attrs:{"x-large":"","color":"yellow darken-2"},on:{"click":function($event){return _vm.trackEngagementPurposes()}}},[_vm._v("Next question "),_c('i',{staticClass:"far fa-long-arrow-right"})]):_c(VBtn,{staticClass:"white--text",staticStyle:{"width":"100%"},attrs:{"disabled":"","x-large":"","color":"yellow darken-2"}},[_vm._v("Next question "),_c('i',{staticClass:"far fa-long-arrow-right"})])],1),_c(VCol,{attrs:{"md":"4","cols":"12"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }