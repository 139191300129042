export default class Word {
    name: string;
    value: number;
    color: string;
    type: string;
    description: string;
    purposes: string;
    stages: string;

    constructor() {
        this.name = '';
        this.value = 0;
        this.color = '';
        this.type = '';
        this.description = '';
        this.purposes = '';
        this.stages = '';
    }
}