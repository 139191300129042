
    import { Vue, Component, Prop } from "vue-property-decorator";

    import Method from '../models/Method';
    import Purpose from '../models/Purpose';
    import Word from "../models/Word";
    import MethodUrl from "../models/MethodUrl";

    @Component({})
    export default class BubblePage extends Vue {
        @Prop() selectedStagesDisplay!: string[];
        @Prop() selectedPurposes!: Purpose[];
        @Prop() filteredMethods!: Method[];
        @Prop() hasBeenStarted!: boolean;
        
        finalMethods: Method[] = [];
        showDialog = false;
        dialogTitle = '';
        dialogType = '';
        dialogText = '';
        dialogUrl = '';
        budgetRange: number[] = [];
        interactionTimeRange: number[] = [];
        interactionNumberRange: number[] = [];
        words: Word[] = [];
        fullBudgetRange: number[] = [];
        fullTimeRange: number[] = [];
        fullInteractionRange: number[] = [];
        selectedPurposeValues: string[] = [];
        methodUrls: MethodUrl[] = [];
        isLoading = false;
        showToaster = true;
        hideToaster = false;

        mounted() {
            this.budgetRange = [1,3];
            this.interactionTimeRange = [1,3];
            this.interactionNumberRange = [1,3];
            this.methodUrls = JSON.parse('[{"name":"25/10 Crowdsourcing","value":"/crowdsourcing"},{"name":"Appreciative Inquiry","value":"/appreciative-inquiry"},{"name":"Bootcamp Translation","value":"/boot-camp"},{"name":"Citizen Juries","value":"/citizen-juries"},{"name":"Community Engagement Studio","value":"/community-engagement"},{"name":"Concept Mapping ","value":"/concept-mapping"},{"name":"Conversation Cafe","value":"/conversation-cafe"},{"name":"Deliberative Polling","value":"/deliberative-polling"},{"name":"Delphi Technique","value":"/delphi-technique"},{"name":"Discovery and Action Dialogues","value":"/discovery-action-dialogues"},{"name":"Ecocycle Planning","value":"/ecocycle-planning"},{"name":"Focus Groups","value":"/focus-groups"},{"name":"Human-Centered Design","value":"/human-centered-design"},{"name":"I-Corps","value":"/i-corps"},{"name":"Key Informant Interviews","value":"/key-informant"},{"name":"Nominal Group Technique","value":"/nominal-group-technique"},{"name":"Online Collaborative Platforms","value":"/online-collaborative-platforms"},{"name":"Online Communities","value":"/online-communities"},{"name":"Purpose to Practice","value":"/purpose-to-practice"},{"name":"Simple Ethnography","value":"/simple-ethnography"},{"name":"Social Network Webbing","value":"/social-network-webbing"},{"name":"Stakeholder Panel/Advisory Committee","value":"/stakeholder-panel"},{"name":"Survey/Questionnaire","value":"/survey-questionnaire"},{"name":"Town Hall Meeting","value":"/town-hall"},{"name":"User Experience Fishbowl","value":"/user-experience-fishbowl"}]');

            this.selectedPurposeValues = this.selectedPurposes?.map(x => x.Value);

            this.finalMethodsFilter();
            this.createWordCloud();

            this.isLoading = false;

            setTimeout(() => {
                if (this.showToaster) {
                    this.showToaster = !this.showToaster;
                    this.hideToaster = !this.hideToaster;
                }
            }, 10000);
        }

        finalMethodsFilter() {
            this.filteredMethods.forEach(method => {
                let addMethodToList = true;

                this.selectedPurposes.forEach(purpose => {
                    let findPurpose = method.Plannings.find(x => x.Name === purpose.Name);

                    if (findPurpose) {
                        addMethodToList = findPurpose.Value;
                    }

                    findPurpose = method.Implementings.find(x => x.Name === purpose.Name);

                    if (addMethodToList && findPurpose) {
                        addMethodToList = findPurpose.Value;
                    }

                    findPurpose = method.Disseminatings.find(x => x.Name === purpose.Name);

                    if (addMethodToList && findPurpose) {
                        addMethodToList = findPurpose.Value;
                    }

                    if (addMethodToList) { this.finalMethods.push(method); }
                })
            })

            this.finalMethods = Array.from(new Set(this.finalMethods));
        }

        createWordCloud() {
            this.words = [];
            this.fullBudgetRange = this.fillRange(this.budgetRange);
            this.fullTimeRange = this.fillRange(this.interactionTimeRange);
            this.fullInteractionRange = this.fillRange(this.interactionNumberRange);

            this.finalMethods.forEach(method => {
                let wordValue = this.calculateWordValue(method.Budget, method.Time, method.Interaction);

                this.words.push( Object.assign( new Word(), {"name": method.Name, "value": wordValue, "color": this.assignWordColor(wordValue), "type": method.Type, "description": method.Description, "purposes": method.Purposes, "stages": method.Stages }));
            });
        }

        // The word weight is calculated by starting at zero.
        // If the method falls within the selected range, it receives three points
        // If the method falls just outside (+/- 1) of the range, it receives one point
        // If the method is further than one tick outside of the range, it receives no points
        // The maximum weight a method can receive is 9 and the minimum is 0
        calculateWordValue(budget: number, time: number, interaction: number) {
            let totalWordValue = 0;

            if (this.fullBudgetRange.includes(budget)) { totalWordValue += 3; }
            else if (this.fullBudgetRange.includes(budget + 1) || this.fullBudgetRange.includes(budget - 1)) { totalWordValue += 1; }

            if (this.fullTimeRange.includes(time)) { totalWordValue += 3; }
            else if (this.fullTimeRange.includes(time + 1) || this.fullTimeRange.includes(time - 1)) { totalWordValue += 1; }

            if (this.fullInteractionRange.includes(interaction)) { totalWordValue += 3; }
            else if (this.fullInteractionRange.includes(interaction + 1) || this.fullInteractionRange.includes(interaction - 1)) { totalWordValue += 1; }
            
            return totalWordValue;
        }

        assignWordColor(wordValue: number) {
            if (wordValue > 8) { return '#01015F'; }
            else if (wordValue > 6) { return '#1C2DCE'; }
            else if (wordValue > 3) { return '#5289EC'; }
            else if (wordValue > 1) { return '#A6C2F4'; }
            return '#808099';
        }
        
        fillRange(range: number[]) {
            const rangeSequence = new Array<number>();
            
            for (let i = range[0]; i <= range[1]; i++ ) {
                rangeSequence.push(i);
            }
            return rangeSequence;
        }

        showMethodDetails(word: Word) {
            this.dialogTitle = word.name;
            this.dialogType = word.type;
            this.dialogText = this.buildModalText(word);
            this.showDialog = true;
            
            const methodLink = this.methodUrls.find(x => x.name === word.name);

            this.dialogUrl = methodLink != undefined ? methodLink.value : '';
        }

        buildModalText(word: Word) {
            let returnText = '';

            returnText += "<p class='font-weight-bold pb-2'>" + word.description + "</p>"
            return returnText;
        }

        setToaster() {
            this.showToaster = !this.showToaster;
            this.hideToaster = !this.hideToaster;
        }
    }
