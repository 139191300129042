
    import { Vue, Component, Prop } from "vue-property-decorator";
    import Purpose from '../models/Purpose';
    
    @Component({})
    export default class EngagementPurposePage extends Vue {
        @Prop() purposes!: Purpose[];
        @Prop() selectedStagesDisplay!: string[];
        selectedPurposes: Purpose[] = [];

        trackEngagementPurposes() {
            this.$emit("trackEngagementPurposes", this.selectedPurposes);
        }

        goBackToStages() {
            this.$emit("goBackToStages");
        }
    }
